// src/ImageCompressor.js
import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import logo from './assets/logo.svg'; // Adjust the path if necessary

const ImageCompressor = () => {
    const [originalImage, setOriginalImage] = useState(null);
    const [compressedImage, setCompressedImage] = useState(null);
    const [dragActive, setDragActive] = useState(false);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setOriginalImage(file);
    };

    const handleImageCompression = async () => {
        if (!originalImage) return;

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };

        try {
            const compressedFile = await imageCompression(originalImage, options);
            const webpImage = await convertToWebP(compressedFile);
            setCompressedImage(webpImage);
        } catch (error) {
            console.error('Error compressing image:', error);
        }
    };

    const convertToWebP = (imageFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                const webpFile = new File([blob], 'compressed_image.webp', {
                                    type: 'image/webp',
                                });
                                resolve(webpFile);
                            } else {
                                reject(new Error('Conversion to WebP failed.'));
                            }
                        },
                        'image/webp',
                        0.95 // Adjust the quality of the webp image here (0 to 1)
                    );
                };
            };
            reader.onerror = (error) => reject(error);
        });
    };

    const downloadCompressedImage = () => {
        if (!compressedImage) return;

        const url = URL.createObjectURL(compressedImage);
        const link = document.createElement('a');
        link.href = url;
        link.download = compressedImage.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === 'dragenter' || event.type === 'dragover') {
            setDragActive(true);
        } else if (event.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setOriginalImage(event.dataTransfer.files[0]);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
                <img src={logo} alt="Company Logo" className="mx-auto mb-4 w-50 h-50" />
                <h1 className="text-2xl font-semibold mb-4 text-center">Image Compressor</h1>
                <div
                    className={`border-2 border-dashed p-6 rounded-lg mb-4 ${dragActive ? 'border-blue-500' : 'border-gray-300'
                        }`}
                    onDragEnter={handleDrag}
                    onDragOver={handleDrag}
                    onDragLeave={handleDrag}
                    onDrop={handleDrop}
                >
                    <input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleImageUpload}
                        id="upload-input"
                    />
                    <label htmlFor="upload-input" className="cursor-pointer">
                        <div className="text-center">
                            <p className="mb-2">Drag and drop an image here, or click to select one</p>
                            <p className="text-gray-500">PNG, JPG, SVG TO WEBP</p>
                        </div>
                    </label>
                </div>
                {originalImage && (
                    <div className="mb-4">
                        <h2 className="text-lg font-semibold">Original Image:</h2>
                        <p>{originalImage.name}</p>
                    </div>
                )}
                <button
                    onClick={handleImageCompression}
                    className="w-full bg-blue-500 text-white py-2 rounded-lg"
                >
                    Compress Image
                </button>
                {compressedImage && (
                    <>
                        <h2 className="text-lg font-semibold mt-4">Compressed Image (WebP):</h2>
                        <img
                            src={URL.createObjectURL(compressedImage)}
                            alt="Compressed"
                            className="mt-2 mb-4 max-w-full h-auto"
                        />
                        <button
                            onClick={downloadCompressedImage}
                            className="w-full bg-green-500 text-white py-2 rounded-lg"
                        >
                            Download Compressed Image
                        </button>
                    </>
                )}
            </div>
            <footer className="mt-4 text-gray-500">
                &copy; {new Date().getFullYear()} Quarec IT Solutions. All rights reserved.
            </footer>
        </div>
    );
};

export default ImageCompressor;
