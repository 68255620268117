import React from 'react';
import './index.css'; 
import ImageCompressor from './Component/ImageCompressor';

function App() {
  return (
    <div className="App">
      <ImageCompressor />
    </div>
  );
}

export default App;
